import { getMeters, putMeter, refreshMeters } from './_meters';

import { createSlice } from '@reduxjs/toolkit';
import { meters as initialState } from '../initialState';
import { buildAsyncReducers } from '../thunkTemplate';

const { reducer, actions } = createSlice({
  name: 'meters',
  initialState,
  reducers: {
    setMeters: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [getMeters, refreshMeters, putMeter]);
  },
});

const { setMeters } = actions;

export { getMeters, refreshMeters, putMeter, setMeters };
export default reducer;
