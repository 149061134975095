import { createAsyncThunk } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import { cloneDeep, concat, get, remove } from 'lodash';

import WebAPIClient, { errorResponseToastr } from '../../api';

import { getMeters } from '../meters';
import { getSites } from '../sites';
import { getMemberships } from '../memberships';
import { setLoader } from '../pages';
import { getLicenses } from '../licenses';

const getOrganizations = createAsyncThunk(
  'organization/getOrganizations',
  async (user, { dispatch, getState, requestId }) => {
    try {
      const { loading, currentRequestId } = getState().organizations;

      if (!user) {
        const { item: data } = getState().user;
        user = { ...data };
      }

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(getSites());
      dispatch(getMeters());
      dispatch(getMemberships());
      dispatch(getLicenses());

      const organizations = await new WebAPIClient().GET(
        '/resource/organizations'
      );
      return {
        data: organizations,
        portfolioMembers: await new WebAPIClient().GET(
          '/resource/portfolio_memberships'
        ),
      };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(setLoader(false));
    }
  }
);

const putOrganization = createAsyncThunk(
  'organization/putOrganization',
  async (organization, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        data: organizations,
      } = getState().organizations;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let _organizations = cloneDeep(organizations);

      const updatedOrganization = await new WebAPIClient().PUT(
        `/resource/organizations/${organization.org_id}`,
        organization
      );
      const organization_id = get(updatedOrganization, 'org_id');
      remove(_organizations, { org_id: organization_id });

      toastr.success('Organization updated');
      return {
        data: concat(_organizations, updatedOrganization),
      };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getOrganizations, putOrganization };
