import { getOrganizations, putOrganization } from './_organizations';

import { createSlice } from '@reduxjs/toolkit';
import { organizations as initialState } from '../initialState';
import { buildAsyncReducers } from '../thunkTemplate';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer } = createSlice({
  name: 'organizations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [getOrganizations, putOrganization]);
  },
});

// Export the reducer, either as a default or named export
export { getOrganizations, putOrganization };

export default reducer;
