import React from 'react';

import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

const Paragraph = ({ children }) => (
  <ListItem disableGutters>
    <Typography variant='body1'>{children}</Typography>
  </ListItem>
);

Paragraph.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default Paragraph;
