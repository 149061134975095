import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import dayjs from 'dayjs';

import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import remove from 'lodash/remove';

import WebAPIClient, { errorResponseToastr } from '../../api';

const getAlarms = createAsyncThunk(
  'alarms/getAlarms',
  async (_, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().alarms;
    const { item: user } = getState().user;

    if (loading !== true || requestId !== currentRequestId || !user) {
      return;
    }

    try {
      dispatch(showLoading());
      const alarms = await new WebAPIClient().GET('/resource/alarms', {
        status: true,
      });
      const triggers = await new WebAPIClient().GET('/resource/alarm_triggers');

      return {
        data: alarms,
        triggers,
        lastUpdate: dayjs(),
      };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const acknowledgeAlarm = createAsyncThunk(
  'alarms/acknowledgeAlarm',
  async (alarm, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading, data: allAlarms } = getState().alarms;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    let alarms = cloneDeep(allAlarms);
    let alarmId = get(alarm, 'alarm_id');

    try {
      dispatch(showLoading());
      const updatedAlarm = await new WebAPIClient().PUT(
        `/resource/alarms/${alarmId}`,
        {
          action: 'ack',
        }
      );

      remove(alarms, { alarm_id: alarmId });
      alarms = concat(alarms, updatedAlarm);

      toastr.success('Alarm Acknowledged');
      return { data: alarms };
    } catch (err) {
      console.log('err: ', err);
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const closeAlarm = createAsyncThunk(
  'alarms/closeAlarm',
  async (alarm, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading, data: allAlarms } = getState().alarms;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    let alarms = cloneDeep(allAlarms);
    let alarmId = get(alarm, 'alarm_id');

    try {
      dispatch(showLoading());
      const updatedAlarm = await new WebAPIClient().PUT(
        `/resource/alarms/${alarmId}`,
        {
          action: 'close',
        }
      );

      remove(alarms, { alarm_id: alarmId });
      alarms = concat(alarms, updatedAlarm);

      toastr.success('Alarm closed');
      return { data: alarms };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteAlarm = createAsyncThunk(
  'alarms/deleteAlarm',
  async (alarm, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading, data: allAlarms } = getState().alarms;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    let alarms = cloneDeep(allAlarms);
    let alarmId = get(alarm, 'alarm_id');

    try {
      dispatch(showLoading());
      await new WebAPIClient().DELETE(`/resource/alarms/${alarmId}`);

      toastr.success('Alarm deleted');
      remove(alarms, { alarm_id: alarmId });
      return { data: alarms };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getAlarms, acknowledgeAlarm, closeAlarm, deleteAlarm };
