import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';

/** MIDDLEWARE **/
import thunkMiddleware from 'redux-thunk';
import {
  loadingBarMiddleware,
  loadingBarReducer,
} from 'react-redux-loading-bar';

/** REDUCERS **/
import { reducer as toastr } from 'react-redux-toastr';
import alarms from './alarms';
import app from './app';
import dialogs from './dialogs';
import images from './images';
import kiosk from './kiosk';
import licenses from './licenses';
import meters from './meters';
import nodes from './nodes';
import organizations from './organizations';
import memberships from './memberships';
import pages from './pages';
import rtos from './rtos';
import sites from './sites';
import user from './user';

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

export const store = configureStore({
  reducer: combineReducers({
    alarms,
    app,
    dialogs,
    images,
    kiosk,
    licenses,
    loadingBar: loadingBarReducer,
    memberships,
    meters,
    nodes,
    organizations,
    pages,
    router: routerReducer,
    rtos,
    sites,
    toastr,
    user,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(routerMiddleware, thunkMiddleware, loadingBarMiddleware()),
});

export const history = createReduxHistory(store);
