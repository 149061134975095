import { getInstLoadData, getPeakData, getRTOs } from './_rtos';

import { createSlice } from '@reduxjs/toolkit';
import { rtos as initialState } from '../initialState';
import { buildAsyncReducers } from '../thunkTemplate';

const { reducer, actions } = createSlice({
  name: 'rtos',
  initialState,
  reducers: {
    setSelectedRTO(state, action) {
      state.selectedRTO = action.payload;
    },
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [getRTOs, getInstLoadData, getPeakData]);
  },
});

const { setSelectedRTO } = actions;
export { setSelectedRTO, getRTOs, getInstLoadData, getPeakData };
export default reducer;
