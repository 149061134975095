import React from 'react';

import styled from '@mui/material/styles/styled';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';

import AuthenticationLinks from './AuthenticationLinks';
export const background = require(`../../assets/images/power-lines.jpg`);

const Root = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100vw',
  background: `url(${background})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

const Main = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

export default function Authentication() {
  return (
    <Root>
      <CssBaseline />
      <Main>
        <Grid container spacing={3} sx={{ maxWidth: 500, width: '100%' }}>
          <Grid item xs={12} sm={12}>
            <AuthenticationLinks />
          </Grid>
        </Grid>
      </Main>
    </Root>
  );
}
