const VEREGY_COLORS = {
  blue: 'rgba(25, 94, 136, 1)',
  lightblue: 'rgba(147, 183, 190, 1)',
  green: 'rgba(115, 176, 67, 1)',
  grey: 'rgba(92, 92, 92, 1)',
  grey50: 'rgba(92, 92, 92, .5)',
  lightgrey: 'rgba(180, 180, 180, 1)',
  lightgrey50: 'rgba(180, 180, 180, .5)',
  orange: 'rgba(233, 180, 75, 1)',
  purple: 'rgba(85, 79, 125, 1)',
  yellow: 'rgba(255, 230, 66, 1)',
  red: 'rgba(214, 73, 51, 1)',
  background: 'rgba(251,251,251,1)',
  brandGrey: 'rgb(147, 183, 190)',
};

export const chartColor = (idx) => {
  const chartColors = [
    VEREGY_COLORS.blue,
    VEREGY_COLORS.green,
    VEREGY_COLORS.orange,
    VEREGY_COLORS.lightgrey,
    VEREGY_COLORS.purple,
  ];
  return chartColors[idx % chartColors.length];
};

export const kioskChartColor = (theme, idx) => {
  const chartColors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    VEREGY_COLORS.orange,
    VEREGY_COLORS.lightgrey,
    VEREGY_COLORS.purple,
  ];
  return chartColors[idx % chartColors.length];
};

export const generateTheme = (mode = 'light', primary, secondary) => {
  primary = primary || VEREGY_COLORS.blue;
  secondary = secondary || VEREGY_COLORS.green;

  return {
    palette: {
      mode,
      primary: { main: primary },
      secondary: { main: secondary },
      warning: {
        main: VEREGY_COLORS.orange,
      },
      error: {
        main: VEREGY_COLORS.red,
      },
      skeleton: VEREGY_COLORS.lightgrey,
      auth: {
        background: 'rgba(35, 35, 35, .95)',
        text: 'rgba(250, 250, 250, 1)',
      },
      grayAccent: 'rgb(147, 183, 190)',
      veregy_colors: VEREGY_COLORS,
    },
    fontAwesomeIcon: { width: 40, height: 40 },
    typography: {
      fontFamily: "'Arial', 'Helvetica', 'sans-serif'",
      defaultFontSize: '0.875rem',
      button: { textTransform: 'none' },
    },
    breakpoints: {
      keys: ['xs', 'sm', 'md', 'lg', 'xl'],
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    dialog: { form: { minWidth: '450px' } },
    drawer: { width: { expanded: 280, collapsed: 0 } },
  };
};
