import { createAsyncThunk } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import { cloneDeep, concat, get, remove } from 'lodash';

import WebAPIClient, { errorResponseToastr } from '../../api';
import { updateNodes } from '../nodes';

const getSites = createAsyncThunk(
  'sites/getSites',
  async (_, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().sites;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    const sites = await new WebAPIClient().GET('/resource/sites');
    return { data: sites };
  }
);

const putSite = createAsyncThunk(
  'sites/putSite',
  async (site, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading, data: allSites } = getState().sites;
    let sites = cloneDeep(allSites);
    try {
      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      let updatedSite = await new WebAPIClient().PUT(
        `/resource/sites/${site.site_id}`,
        site
      );
      remove(sites, { site_id: get(updatedSite, 'site_id') });
      sites = concat(sites, updatedSite);
      toastr.success('Site updated');
      dispatch(updateNodes({ sites }));

      return { data: sites };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getSites, putSite };
